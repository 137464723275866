import React from 'react';
import { Tab, Tabs, Box } from '@mui/material';
import { ga4Events } from '../../../../utils';
import axios from "axios";
import { useDispatch } from 'react-redux';
const CatalogTypeSelect = ({ category, setCategory }) => {
     const dispatch = useDispatch()
    const CatlogTypes = ['Brands', 'Parts', 'Make', 'Lubricants', 'Battery'];   
    const [value, setValue] = React.useState(0);
    const handleChangeCategory = (e, newValue) => {
        //  if(CatlogTypes[newValue]== 'Lubricants'){
        //     axios
        //     .get("api/brand/allLub")
        //     .then(res => {
        //         dispatch({
        //             type: "GET_ALL_LUBS_SUCCESS",
        //             data: res.data
        //         })
        //     })
        //     .catch(err => {
        //         console.log(err)
        //     });
        // }
        setCategory(CatlogTypes[newValue])
        setValue(newValue);
        ga4Events({
            action: "catalog_type_click",
            category: "Listing",
            subcategory_id: newValue,
            subcategory_name: CatlogTypes[newValue]
        })
    }
    return (
        <>
            <Box sx={{ width: '100%' }}>
              <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs   value={value}  onChange={handleChangeCategory} aria-label="basic tabs example" sx={{backgroundColor:'#F9F9F9'}}>
                        {CatlogTypes.map((cat, index) => {
                            if(cat == 'Tires') return (<Tab textColor='primary' label={'Tyres'} />)
                            if(cat == 'Parts') return (<Tab textColor='primary' label={'Category'} />)
                            return (<Tab textColor='primary' label={cat} />)
                        })}
                    </Tabs>
                </Box>
            </Box>
        </>
    )
}

CatalogTypeSelect.propTypes = {
}

export default CatalogTypeSelect
