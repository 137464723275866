import axios from "axios";
import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import PurchaseSlider from "./PurchaseSlider";

const Purchase = () => {
  const [purchaseData, setPurchaseData] = useState([]);
  const getPurchase = async (currentUser) => {
    let response = await axios.post(
      `api/order/buy-again?buyer=${currentUser?._id}&status=delivered&page=1&size=12`
    );
    response?.data && response?.data?.length && setPurchaseData(response?.data[0]?.data);
  }
  useEffect(() => {
    const currentUser = JSON.parse(localStorage.getItem("login"));
    if (currentUser) {
      getPurchase(currentUser);
    }
  }, []);


  return (
    purchaseData && purchaseData.length ?
    <>
      <div style={{ marginTop: "4rem" }} className="section-title">
        <div className="d-flex align-items-center justify-content-between">
          <div>
            <h1 className="mb-0 about_us_title">Buy Again</h1>
          </div>
          <NavLink
            to={`/purchase-list`}
            style={{ fontWeight: "700" }}
          >
            VIEW ALL
          </NavLink>
        </div>
      </div>
      <PurchaseSlider
        skus={purchaseData}
      />
    </> : <></>
  );
};

export default Purchase;
