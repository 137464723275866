import React from 'react';
import { Modal, ModalBody } from 'reactstrap';
import { useFormik } from 'formik';
import * as yup from 'yup';
import './login.css';
import FormikValidationError from './FormikValidationError';
import { useState } from 'react';
import { LogInAc } from '../../../Store/action/loginActions';
import { visitor1 } from '../../../Store/action/listingActions';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import 'react-phone-input-2/lib/material.css';
import PhoneInput from 'react-phone-input-2';
import axios from 'axios';
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
import {
  emailValidationSchema,
  otpValidation,
  phoneValidationSchema,
} from './validationSchema';
import { useEffect } from 'react';
import {
  Visibility,
  VisibilityOff,
} from '@material-ui/icons';
import { IconButton, InputAdornment, makeStyles, TextField, Typography } from '@material-ui/core';
import { ga4Events, popUp, validateFile } from '../../../utils';
import OTPInput from 'react-otp-input';
import { useHistory } from 'react-router';
import { async } from 'q';
import { uploadFileToAssets } from '../../../services/upload';
import { CallRoundedIcon, EmailOutlinedIcon, WhatsAppIcon, WifiCalling3OutlinedIcon } from '../../common/MaterialIcons';
import { NavLink } from "react-router-dom";

const useStyles = makeStyles({
  customTextField: {
    '& .MuiInputBase-root': {
      height: '42px',
      fontSize: '14px',
      fontWeight: 400,

    },
    '& .MuiOutlinedInput-input': {
      padding: '10px'
    }
  }
})

const initialValue = {
  email: '',
  password: '',
  phone: '',
  otp: '',
  newPassword: '',
  confirmPassword: '',
  isChecked: true,
  otpPhone: '',
  mobileOtp: ''
};
const LoginModal = ({ isModalOpen, toggle, login, visitor, loginToggle, isVisitor, redirectURL }) => {
  const classes = useStyles();
  const [isSignWithPhone, setSignWithPhone] = useState(false);
  const [isSignWithEmail, setSignWithEmail] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isForgetPass, setIsForgetPass] = useState(false);
  const [isOtpFound, setIsOtpFound] = useState(false);
  const [isValidOtp, setIsValidOtp] = useState(false);
  const [value, setValue] = useState('');
  const [mobileValue, setMobileValue] = useState('');
  const [passwordVisibility, setPasswordVisibility] = useState(false);
  const [newVisibility, setNewVisibility] = useState(false);
  const [confirmVisibility, setConfirmVisibility] = useState(false);
  const [isResetPassword, setIsResetPassword] = useState(false);
  const [initialValues, setInitialValues] = useState(initialValue);
  const [isSignupWithOtp, setIsSignupWithOtp] = useState(true);
  const [isOtpByPhone, setIsOtpByPhone] = useState(false);
  const [seconds, setSeconds] = useState(40);
  const history = useHistory();
  const [selectedCountry, setSelectedCountry] = useState('ae');
  const [emailLoginPasswordShow, setEmailLoginPasswordShow] = useState(false);
  const [isPopup, setIsPopup] = useState(false);
  const [isHelpModal, setIsHelpModal] = useState(false);
  // registeredUser == true ---> Existing User
  const [registeredUser, setRegisteredUser] = useState(false);
  const [showIndividualMessage, setShowIndividualMessage] = useState(false);
  const [selectedUserType, setSelectedUserType] = useState('');

  useEffect(() => {
    const timer = setInterval(() => {
      isOtpByPhone && setSeconds((prevSeconds) => prevSeconds > 0 && Math.floor(prevSeconds - 1));
    }, 1000);

    return () => clearInterval(timer);
  }, [isOtpByPhone]);

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
  };

  const validationSchema = (isSignWithPhone, value, isForgetPass, isSignupWithOtp, isSignWithEmail) => {
    let schema = {
      email: yup.mixed().required('Email ID is required'),
      password: yup.mixed().required('Password is required'),
    };

    if (isSignWithPhone && isForgetPass) {
      schema = {
        ...schema,
        email: yup.mixed().nullable(),
        password: yup.mixed().nullable(),
      };
    }
    if (isSignWithEmail && !emailLoginPasswordShow) {
      schema = {
        ...schema,
        email: yup.string().required('Email ID is required').email('Invalid Email ID, Please enter again'),
        password: yup.mixed().nullable(),
      };

    }
    if (isSignWithEmail && emailLoginPasswordShow) {
      schema = {
        ...schema,
        email: yup.string().required('Email ID is required').email('Invalid Email ID, Please enter again'),
        password: yup.string().required('Password is required'),
      };

    }
    if (isSignupWithOtp) {
      schema = {
        ...schema,
        email: yup.mixed().nullable(),
        password: yup.mixed().nullable(),
      };
    }
    if (isSignWithPhone && !value) {
      schema = {
        ...schema,
        email: yup.mixed().nullable(),
        // password: yup.mixed().nullable(),
      };
    } else if (isSignWithPhone) {
      schema = {
        ...schema,
        email: yup.mixed().nullable(),
      };
    }
    return yup.object().shape(schema);
  };

  //toast notification
  const notify = (message) => {
    // console.log('error message' + message);
    popUp(message);
  };
  const remove4th0 = (zero) => {
    zero = zero[4] === '0' ? zero.slice(0, 4) + zero.slice(5) : zero;
    console.log(zero)
    return zero;
  }

  const [otp, setOtp] = useState('');
  const [mobileOtp, setMobileOtp] = useState('');
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [errorM, setErrorM] = useState('');
  const [token, setToken] = useState("");
  const [tradeLicenseUpload, setTradeLicenseUpload] = useState(null);
  const [isValidOtpByPhone, setIsValidOtpByPhone] = useState(false);
  const [errorsMsg, setErrors] = useState({});
  const [isSkip, setIsSkip] = useState(false);

  const validateForm = () => {
    const errors = {};
    if (!tradeLicenseUpload?.length) {
      errors.tradeLisenceNo = 'Trade Lisence is required';
    }
    return errors;
  };

  // console.log(redirectURL, "redirectURLredirectURL")

  const [isClosed, setIsClosed] = useState(false);
  const submitHandler = async (values) => {
    const formErrors = validateForm();
    setIsLoading(true);
    if (isValidOtpByPhone && !tradeLicenseUpload?.length) {
      setErrors(formErrors);
      setIsLoading(false);
    }
    else if (isSignupWithOtp && !mobileValue) {
      setIsLoading(false);
      handleCustomError('otpPhone', true);
    }
    else if (isSignWithPhone && !value?.length) {
      setIsLoading(false);
      handleCustomError('phone', true);
    }
    else if (!otp.length && isResetPassword) {
      setError(true);
      setIsLoading(false);
      setErrorMessage('OTP is required');
      return;
    }
    else if (otp.length < 6 && isResetPassword) {
      setError(true);
      setIsLoading(false);
      setErrorMessage('Please check empty OTP');
      return;
    }
    else if (!mobileOtp.length && isOtpByPhone) {
      setError(true);
      setIsLoading(false);
      setErrorMessage('OTP is required');
      return;
    }
    else if (mobileOtp.length < 6 && isOtpByPhone) {
      setError(true);
      setIsLoading(false);
      setErrorMessage('Please check empty OTP');
      return;
    }

    else if (isValidOtp) {
      axios
        .post('/api/users/update-password', {
          method: isSignWithPhone ? 'phone' : 'email',
          identity: isSignWithPhone ? remove4th0(value?.replace(/\s/g, '').trim()) : values?.email?.trim()?.toLowerCase(),
          password: values.confirmPassword?.trim(),
        })
        .then((res) => {
          notify("Password successfully updated");
          setIsLoading(false);
          setIsValidOtp(false);
          setIsOtpFound(false);
          setValue('');
          setMobileValue('');
          resetForm();
          setSignWithPhone(false);
          setIsForgetPass(false);
          setIsResetPassword(false);
          setPasswordVisibility(false);
          setNewVisibility(false);
          setConfirmVisibility(false);
          setIsOtpByPhone(false);
          setIsSignupWithOtp(true);
          setSignWithEmail(false);

          if (isSignWithPhone) {
            ga4Events({
              action: 'forget_password_via_mobile',
              category: 'User',
              phone: remove4th0(value?.replace(/\s/g, '').trim()),
            });
          } else {
            ga4Events({
              action: 'forget_password_via_email',
              category: 'User',
              email: values.email?.trim()?.toLowerCase(),
            });
          }

        })
        .catch((err) => {
          setIsLoading(false);
          notify(err.response?.data?.message);
        });

    }
    else if (isResetPassword) {
      handleCustomError('phone', false);
      axios
        .post(`/api/users/verify-otp`, {
          identify: isSignWithPhone ? remove4th0(value?.replace(/\s/g, '').trim()) : values?.email?.trim().toLowerCase(),
          code: otp?.trim(),
          forRegister: false,
        })
        .then((res) => {
          setIsValidOtp(true);
          setIsLoading(false);
          if (isSignWithPhone) {
            ga4Events({
              action: 'verify_phone_otp',
              category: 'User',
              phone: '+' + remove4th0(value?.replace(/\s/g, '').trim()),
            });
          } else {
            ga4Events({
              action: 'verify_email_otp',
              category: 'User',
              email: values?.email?.trim().toLowerCase(),
            });
          }
        })
        .catch((err) => {
          setIsLoading(false);
          //Added for local debug Start
          // setIsValidOtp(true);
          //Added for local debug End
          notify(err.response?.data?.message);
        });
    }
    else if (isForgetPass && isSignWithEmail) {
      handleCustomError('phone', false);
      await axios
        .post(`/api/users/get-otp`, {
          value: values.email?.trim()?.toLowerCase(),
          by: "email",
          forRegister: false
        })
        .then((res) => {
          setIsOtpFound(res?.status === 200 && true);
          setIsLoading(false);
          setIsResetPassword(true);
        })
        .catch((err) => {
          setIsOtpFound(false);
          //Added for local debug Start
          // setIsOtpFound(true);
          // setIsResetPassword(true);
          //Added for local debug End
          setIsLoading(false);
          notify(err.response?.data?.message);
        });

    }
    else if (isForgetPass && isSignWithPhone) {
      handleCustomError('phone', false);
      await axios
        .post(`/api/users/get-otp`, {
          value: remove4th0(value?.replace(/\s/g, '').trim()),
          by: "sms",
          forRegister: false
        })
        .then((res) => {
          setIsOtpFound(res?.status === 200 && true);
          setIsLoading(false);
          setIsResetPassword(true);
        })
        .catch((err) => {
          setIsOtpFound(false);
          setIsLoading(false);
          notify(err.response?.data?.message);
        });

    }
    else if (isSignWithPhone) {
      handleCustomError('phone', false);
      const reqData = {
        method: 'phone',
        email: "",
        password: values.password?.trim(),
        checked: values.isChecked,
        phone: remove4th0(value?.replace(/\s/g, '').trim())
      };
      await axios
        .post(`/api/users/login`, reqData)
        .then(async (res) => {
          await visitor(0);
          await login({ ...res.data.user, token: res.data.token });
          loginToggle();
          ga4Events({
            action: 'successful_login',
            category: 'User',
            phone: '+' + value ? remove4th0(value?.replace(/\s/g, '').trim()) : '',
            bp_user_id: res.data?.user?._id,
          });
          window.location.reload();
          redirectURL && (window.location.href = redirectURL);
        }).catch((err) => {
          setIsLoading(false);
          notify(err.response?.data?.message);
          ga4Events({
            action: 'failed_login',
            category: 'User',
            phone: '+' + value ? remove4th0(value?.replace(/\s/g, '').trim()) : '',
          });
        });
    }
    else if (isSignWithEmail) {
      if (emailLoginPasswordShow) {
        const reqData = {
          method: 'email',
          email: values.email?.trim(),
          password: values.password?.trim(),
          checked: values.isChecked,
          phone: ""
        };
        await axios
          .post(`/api/users/login`, reqData)
          .then(async (res) => {
            await visitor(0);
            await login({ ...res.data.user, token: res.data.token });
            loginToggle();
            ga4Events({
              action: 'successful_login',
              category: 'User',
              email: values.email?.trim(),
              bp_user_id: res.data?.user?._id,
            });
            window.location.reload();
            redirectURL && (window.location.href = `${redirectURL}`);
          }).catch((err) => {
            setIsLoading(false);
            notify(err.response?.data?.message);
            ga4Events({
              action: 'failed_login',
              category: 'User',
              email: values.email?.trim(),
            });
          });
      } else {
        setEmailLoginPasswordShow(true);
        setIsLoading(false);
      }
    }
    else if (isValidOtpByPhone && tradeLicenseUpload?.length) {
      const details = {
        trade_license_upload: tradeLicenseUpload,
      };
      axios
        .post(`/api/auth/upload-trade-license`, details, {
          headers: {
            Authorization: token,
            platform: "web"
          }
        })
        .then(async (res) => {
          setIsClosed(false);
          popUp("You have successfully registered as buyer.");
          loginToggle();
          setIsLoading(false);
          await visitor(0);
          await login({ ...res.data.user, token: res.data.token });
          ga4Events({
            action: 'trade_license_submitted',
            category: 'Register'
          });
          ga4Events({
            action: 'successful_login',
            category: 'User',
            bp_user_id: res.data?.user?._id,
          });
          window.location.reload();
          redirectURL && (window.location.href = `${redirectURL}`);
        })
        .catch((err) => {
          popUp(err.response.data.message);
          setIsLoading(false);
          ga4Events({
            action: 'failed_login',
            category: 'User'
          });
        });

    }
    else if (isOtpByPhone) {
      await axios
        .post(`/api/auth/verify-otp`, {
          phone: remove4th0(mobileValue?.replace(/\s/g, '').trim()),
          code: mobileOtp,
          isB2C: registeredUser
        }, {
          headers: {
            Authorization: token
          }
        })
        .then(async (res) => {
          if (res?.data?.tlSetting?.enabledTL && !res.data.forLogin) {
            setIsLoading(false);
            setIsValidOtpByPhone(true);
            setIsClosed(true)
            setToken(res.data.token);
            ga4Events({
              action: 'trade_license_upload_viewed',
              category: 'User',
            });

          } else {
            setIsLoading(false);
            setIsOtpByPhone(false);
            await visitor(0);
            await login({ ...res.data.user, token: res.data.token });
            loginToggle();
            if (!showIndividualMessage) {
              toast.success(
                <div>
                  <div style={{ textAlign: 'center', margin: 0, padding: 0, paddingTop: '6px' }}>
                    Thank you for registering with us! 
                  </div> 
                  <br />
                  <div style={{ padding: '10px', paddingTop: 0 }}>
                    Your catalog is personalized to your needs. Explore and enjoy your curated selection.
                    </div>
                </div>, {
                position: "top-center",
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                enabledHtml: true
              });
            }
            ga4Events({
              action: 'otp_submitted',
              category: 'User',
            });
            ga4Events({
              action: 'successful_login',
              category: 'User',
              bp_user_id: res.data?.user?._id,
            });
            setTimeout(() => {
              window.location.reload();
              redirectURL && (window.location.href = `${redirectURL}`);
            }, 5000)
          }
        })
        .catch((err) => {
          setIsLoading(false);
          if (err.response?.status === 401) {
            notify("OTP code expired, Please try again.");
          } else {
            notify(err.response?.data?.message);
            ga4Events({
              action: 'failed_login',
              category: 'User'
            });
          }

        });
    }
    else if (isSignupWithOtp && mobileValue) {
      handleCustomError('otpPhone', false);

      await axios
        .post(`/api/auth/get-otp`, { phone: remove4th0(mobileValue?.replace(/\s/g, '').trim()) })
        .then((res) => {
          setToken(res.data.token)
          setIsLoading(false);
          setIsOtpByPhone(true);
          // forLogin == true ---> Existing User
          setRegisteredUser(res.data.forLogin);
          setShowIndividualMessage(res.data.forLogin);
          ga4Events({
            action: 'mobile_number_submit_clicked',
            category: 'User',
            phone: remove4th0(mobileValue?.replace(/\s/g, '').trim())
          });
        })
        .catch((err) => {
          setIsLoading(false);
          //Added for local debug Start
          // setIsOtpByPhone(true);
          //Added for local debug End
          if (err.response?.data?.msg?.code === 60200) {
            let error = "Invalid Number-Please enter a valid UAE mobile number starting with'5'"
            notify(error);
          }
          else {
            notify(err.response?.data?.message);
          }
        });

    }

  };

  const onResetSubmit = async (values) => {
    if (isOtpFound && isForgetPass && isSignWithEmail) {
      handleCustomError('phone', false);
      await axios
        .post(`/api/users/get-otp`,
          {
            value: values.email?.trim().toLowerCase(),
            by: "email",
            forRegister: false
          })
        .then((res) => {
          setIsOtpFound(res?.status === 200 && true);
          setIsResetPassword(true);
          setIsLoading(false);
        })
        .catch((err) => {
          setIsOtpFound(false);
          setIsLoading(false);
          notify(err.response?.data?.message);
        });
    } else if (isOtpFound && isForgetPass && isSignWithPhone) {
      handleCustomError('phone', false);
      await axios
        .post(`/api/users/get-otp`, {
          value: remove4th0(value?.replace(/\s/g, '').trim()),
          by: "sms",
          forRegister: false
        })
        .then((res) => {
          setIsOtpFound(res?.status === 200 && true);
          setIsResetPassword(true);
          setIsLoading(false);
        })
        .catch((err) => {
          setIsOtpFound(false);
          setIsLoading(false);
          notify(err.response?.data?.message);
        });
    }
  };


  const setSelectedFile = async (file, name) => {
    if (file) {
      try {
        const data = await uploadFileToAssets({ file: file, type: "user" });
        setTradeLicenseUpload(data?._id);
        ga4Events({
          action: 'trade_license_uploaded',
          category: 'User',
        });
      } catch (error) {
        popUp(error?.message)
      }
    }
  }

  //for phone validation formik doesnt support so custom validation
  const [customErrorData, setCustomErrorData] = useState({ phone: false, otpPhone: false });
  const handleCustomError = (errorFor, status) => {
    setCustomErrorData((prev) => {
      return { ...prev, [errorFor]: status };
    });
  };

  const handleInputChange = (e) => {
    e?.target?.value && setValue(e?.target?.value);
    e?.target?.value && setMobileValue(e?.target?.value);
    const inputPhoneNumber = e?.target?.value;
    //setPhoneNumber(inputPhoneNumber);
    if (inputPhoneNumber && inputPhoneNumber.length !== 16) {
      setErrorM('Mobile Number must be 9 digits');
      setIsPopup(true);
    } else {
      setIsPopup(false);
      setErrorM('');
    }

  }
  const onBlurChnage = (e) => {
    let mobileNo = e?.target?.value?.replace(/\s/g, '').trim()
    if (mobileNo.length >= 6) {
      var phoneno = /^\+9710/;
      if (mobileNo.match(phoneno)) {
        setErrorM('Invalid Mobile Number');
      }
      else { setErrorM(''); }
    }

  }

  const {
    values,
    handleChange,
    handleSubmit,
    handleBlur,
    setFieldValue,
    touched,
    errors,
    setFieldTouched,
    resetForm,
  } = useFormik({
    enableReinitialize: true,
    initialValues: initialValues,
    validationSchema: isValidOtp
      ? phoneValidationSchema()
      : (isOtpFound || isOtpByPhone && !isSignWithEmail && !isSignWithPhone)
        ? otpValidation()
        : isForgetPass && !isSignWithPhone
          ? emailValidationSchema()
          : validationSchema(isSignWithPhone, value, isForgetPass, isSignupWithOtp, isSignWithEmail),
    onSubmit: (values) => {
      console.log("value", values);
      submitHandler(values);
    },
  });

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const resendOtpForMobile = async (values) => {
    handleCustomError('otpPhone', false);
    await axios
      .post(`/api/auth/get-otp`, { phone: remove4th0(mobileValue?.replace(/\s/g, '').trim()) })
      .then((res) => {
        setToken(res.data.token)
        setIsLoading(false);
        setIsOtpByPhone(true);
        setSeconds(40);
      })
      .catch((err) => {
        setIsLoading(false);
        notify(err.response?.data?.message);
      });

  }

  const handleSkip = async () => {
    setIsSkip(true);
    setIsValidOtpByPhone(false);
  }

  useEffect(() => {
    return () => {
      resetForm();
      setMobileValue("");
      setValue("")
      setSignWithPhone(false);
      setSignWithEmail(false);
      setIsLoading(false);
      setIsForgetPass(false);
      setIsOtpFound(false);
      setIsValidOtp(false);
      setIsResetPassword(false);
      setIsOtpByPhone(false);
      setIsSignupWithOtp(true);
      setOtp("");
      setTradeLicenseUpload("");
      setIsValidOtpByPhone(false);
      setIsClosed(false);
      setMobileOtp("")
      setToken("");
      setIsSkip(false);
      setSelectedCountry('ae')
      setEmailLoginPasswordShow(false);
      // Cleanup
      setIsPopup(true);

    };
  }, [isModalOpen]);

  const handleDeleteVerifiedUser = async () => {
    await axios
      .delete(`/api/auth`, {
        headers: {
          Authorization: token
        }
      })
      .then((res) => {
        resetForm();
      })
      .catch((err) => {
        setIsLoading(false);
      });

  }
  useEffect(() => {
    !isModalOpen && isClosed && handleDeleteVerifiedUser()
  }, [isModalOpen, isClosed])

  const handleGaEventWhatsApp = () => {
    ga4Events({
      action: 'whatsapp_icon_click',
      category: 'Chat'
    });
  }

  return (
    <>
      <Modal
        isOpen={isModalOpen}
        toggle={(e) => {
          toggle(e);
          setInitialValues(initialValue);
        }}
        centered
        size="lg"
        style={{ maxWidth: '450px' }}
        contentClassName="border-radius-2"
      >
        <ModalBody>
          {/* <div className='mb-3 d-flex justify-content-between px-4 py-1'>
            <h3>{(isSignWithEmail || isSignWithPhone) ? "Login" : "Login / Register into BuyParts"}</h3>
            <button
              type='button'
              className='close mr-2'
              data-dismiss='modal'
              aria-label='Close'
              style={{ outline: 'none' }}
              onClick={(e) => {
                toggle(e);
                isVisitor && history.push("/");
              }}
            >
              {!isValidOtpByPhone && !isSkip && <img
                src='/assets/img/close.png'
                alt='close'
                style={{ fontSize: 37, height: 19 }}
              />}
            </button>
          </div> 
          <hr style={{ margin: 0, padding: 0 }} />
          */}
          <div className='p-5'>
            <button
              type='button'
              className='close close-pop mr-2'
              data-dismiss='modal'
              aria-label='Close'
              style={{ outline: 'none' }}
              onClick={(e) => {
                toggle(e);
                isVisitor && history.push("/");
              }}
            >
              {!isValidOtpByPhone && !isSkip && <img
                src='/assets/img/close.png'
                alt='close'
                style={{ fontSize: 18, height: 14 }}
              />}
            </button>
            {!isSkip &&
              <div className='text-center mt-2 mb-4'>
                <div className='align-items-center text-center'>
                  <Typography variant='h5' style={{ fontWeight: '700', fontSize: '18px' }}>
                    {isValidOtp
                      ? 'Create new password'
                      : isValidOtpByPhone ? ""
                        : (isOtpFound && !isValidOtp || (isOtpByPhone && !isSignWithEmail && !isSignWithPhone && !isForgetPass))
                          ? (!isForgetPass ? (!registeredUser ? 'Register into BuyParts24 as:' : 'Login/Register into BuyParts24') : 'Reset Password')
                          : isForgetPass
                            ? 'Reset your password'
                            : 'Login/Register into BuyParts24'}
                  </Typography>
                  <Typography variant='h6' style={{ fontWeight: '400', fontSize: '12px' }}>
                    {
                      !isSignWithEmail && !isOtpFound && !isOtpByPhone &&
                      'Please enter your valid phone no. '
                    }
                    {
                      ((!isSignupWithOtp && !isForgetPass && !isOtpFound && !isOtpByPhone && !isSkip)
                        || isForgetPass) && !isOtpFound && !emailLoginPasswordShow &&
                      'Please enter your valid email id. '
                    }
                    {/* {
                      ((!isSignupWithOtp && !isForgetPass && !isOtpFound && !isOtpByPhone && !isSkip)
                        || isForgetPass) && !isOtpFound &&
                      'Please enter your valid email id. '
                    } */}
                    {
                      (isSignWithEmail || isSignWithPhone) && isForgetPass && !isOtpFound && emailLoginPasswordShow &&
                      'Please enter your valid email id.'
                    }
                    {
                      (isSignWithEmail || isSignWithPhone) && !isForgetPass && !isOtpFound && emailLoginPasswordShow &&
                      'Please enter your valid email id and password. '
                    }

                    {
                      (isOtpFound && !isValidOtp && !isForgetPass || (isOtpByPhone && !isSignWithEmail && !isSignWithPhone && !isForgetPass)) && registeredUser &&
                      'Enter the 6-digit verification code sent to'
                    }
                    {
                      isOtpFound && !isValidOtp && <>
                        <span>Please insert the OTP sent to your email id <b>{values.email}</b> and set new password</span>
                        <span style={{ paddingLeft: 20, color: "#1D71B8", cursor: 'pointer', fontWeight: "bold" }} onClick={() => {
                          setError(false);
                          setErrorMessage('');
                          setIsResetPassword(false);
                          setIsOtpFound(false);
                          setIsForgetPass(true);
                        }}>CHANGE</span>
                      </>
                    }
                  </Typography>
                  {
                    (isOtpFound && !isValidOtp && !isForgetPass || (isOtpByPhone && !isSignWithEmail && !isSignWithPhone && !isForgetPass)) && registeredUser &&
                    <>
                      <Typography variant='h6' style={{ fontWeight: '700', fontSize: '14px', marginBottom: 20 }}>
                        <span>{mobileValue}</span>
                        <span style={{ paddingLeft: 20, color: "#1D71B8", cursor: 'pointer', fontWeight: "bold" }} onClick={() => {
                          setIsOtpFound(false);
                          setIsOtpByPhone(false);
                          setMobileOtp('');
                        }}>CHANGE</span>
                      </Typography>
                      <PhoneInput
                        placeholder='Enter phone number'
                        name='otpPhone'
                        country={selectedCountry}
                        value={remove4th0(mobileValue?.replace(/\s/g, '').trim())}
                        inputStyle={{
                          height: '42px',
                          fontSize: '14px',
                        }}
                        disabled
                        dropdownStyle={{ width: '400px' }}
                        fullWidth="true"
                      />
                    </>
                  }
                </div>
              </div>}
            <form onSubmit={handleSubmit}>
              {!isOtpByPhone && !isSignWithEmail && !isSignWithPhone && !isForgetPass && !isSkip && <>
                <div className='form-outline mb-3'>
                  <PhoneInput
                    placeholder='Enter phone number'
                    name='otpPhone'
                    country={selectedCountry}
                    value={remove4th0(mobileValue?.replace(/\s/g, '').trim())}
                    inputStyle={{
                      height: '42px',
                      fontSize: '14px',
                    }}
                    dropdownStyle={{ width: '400px' }}
                    fullWidth="true"
                    onChange={(phoneNumber, country, e) => {
                      e.preventDefault();

                      handleInputChange(e);
                      if (selectedCountry !== country?.countryCode) {
                        ga4Events({
                          action: 'country_changed',
                          category: 'User',
                        });
                        setSelectedCountry(country?.countryCode)
                      }
                    }}
                    onBlur={(e) => {
                      e.preventDefault();
                      onBlurChnage(e);
                    }}
                  />
                  {errorM && <div className="text-danger">{errorM}</div>}
                  {customErrorData?.otpPhone && !mobileValue?.length && (
                    <span className='text-danger'>
                      {' '}
                      <span className='ic-error'></span>
                      Phone number is required
                    </span>
                  )}
                </div>
                {/* <div>
                  <p style={{ color: "black" }}>We'll text you to confirm your number. Standard message and data rates may apply.
                    <u style={{ cursor: "pointer" }} onClick={(e) => {
                      toggle(e);
                      ga4Events({
                        action: 'privacy_policy_viewed',
                        category: 'User',
                      });
                      history.push("/privacy");
                    }}>Privacy Policy</u></p>
                </div> */}
              </>}
              {
                isOtpByPhone && !isSignWithEmail && !isSignWithPhone && !isForgetPass && !isValidOtpByPhone && !isSkip && registeredUser && <>
                  <div className='form-outline'
                    style={{ textAlign: "center", padding: "0 3px", justifyContent: "center", display: "flex" }}>
                    <OTPInput
                      inputStyle={{
                        width: "46px",
                        height: "43px",
                        textAlign: "center",
                        borderRadius: '4px',
                        border: '1px solid #ccc',
                      }}
                      value={mobileOtp}
                      shouldAutoFocus
                      className="otp-input-div"
                      onChange={(value) => {
                        setError(false);
                        setMobileOtp(value);
                        ga4Events({
                          action: 'otp_entered',
                          category: 'User',
                        });
                      }}
                      numInputs={6}
                      renderSeparator={<span style={{ paddingLeft: 10 }}></span>}
                      renderInput={(props) => <input {...props}
                        type="text"
                        inputmode="numeric"
                      />}
                      inputType='number'
                    />
                  </div>
                  <div className="text-danger" style={{ justifyContent: "center", display: "flex" }}>
                    {error && errorMessage && errorMessage}
                  </div>
                </>
              }

              {
                isSkip &&
                <>
                  <div
                    style={{
                      fontSize: "19px",
                      color: "black",
                      marginBottom: "4px"

                    }}>
                    Unfortunately, If you skip this step,
                    you wont be able to register to our platform, kindly upload your trade license to proceed with registration.
                  </div>

                </>
              }
              {isValidOtpByPhone && <>
                <div className="form-group">
                  <label
                    htmlFor="tradeLicenseUpload"
                    className="form-label"
                  >
                    Trade License Upload <span style={{ color: 'red' }}>*</span>
                  </label>

                  <input
                    type="file"
                    className="form-control"
                    id="tradeLicenseUpload"
                    onChange={(e) => {
                      let isLt5M = validateFile(e.target.files[0])
                      if (!isLt5M) {
                        e.target.value = ''
                        return
                      }
                      setSelectedFile(
                        e.target.files[0],
                        "tradeLicenseUpload"
                      )
                    }
                    }
                  />
                  <div className="text-danger">
                    {!tradeLicenseUpload && errorsMsg.tradeLisenceNo && errorsMsg.tradeLisenceNo}
                  </div>
                </div>
              </>
              }


              {isValidOtp && <>
                <div className='form-outline mb-4'>
                  <TextField
                    id='standard-basic'
                    name='newPassword'
                    type={newVisibility ? 'text' : 'password'}
                    fullWidth
                    placeholder='New Password'
                    variant='outlined'
                    hiddenLabel
                    InputProps={{
                      endAdornment: (
                        <InputAdornment
                          position='end'
                          style={{
                            backgroundColor: 'transparent',
                            fontSize: '15px',
                          }}
                        >
                          <IconButton
                            aria-label='toggle password'
                            edge='end'
                            onClick={() => setNewVisibility(!newVisibility)}
                            onMouseDown={handleMouseDownPassword}
                          >
                            {newVisibility ? (
                              <img
                                style={{ width: "20px" }}
                                src="/assets/img/eye-visiable.svg"
                                alt="Help"
                              />
                            ) : (
                              <img
                                style={{ width: "20px" }}
                                src="/assets/img/eye-visiable-off.svg"
                                alt="Help"
                              />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    onChange={handleChange}
                    value={values.newPassword}
                    onBlur={handleBlur}
                    className={classes.customTextField}
                  />
                  <FormikValidationError
                    name='newPassword'
                    touched={touched}
                    error={errors}
                  />
                </div>
                <div className='form-outline mb-4'>
                  <TextField
                    id='standard-basic'
                    name='confirmPassword'
                    type={confirmVisibility ? 'text' : 'password'}
                    fullWidth
                    placeholder='Confirm Password'
                    variant='outlined'
                    hiddenLabel
                    InputProps={{
                      endAdornment: (
                        <InputAdornment
                          position='end'
                          style={{
                            backgroundColor: 'transparent',
                            fontSize: '15px',
                          }}
                        >
                          <IconButton
                            aria-label='toggle password'
                            edge='end'
                            onClick={() =>
                              setConfirmVisibility(!confirmVisibility)
                            }
                            onMouseDown={handleMouseDownPassword}
                          >
                            {confirmVisibility ? (
                              <img
                                style={{ width: "20px" }}
                                src="/assets/img/eye-visiable.svg"
                                alt="Help"
                              />
                            ) : (
                              <img
                                style={{ width: "20px" }}
                                src="/assets/img/eye-visiable-off.svg"
                                alt="Help"
                              />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.confirmPassword}
                    className={classes.customTextField}
                  />
                  <FormikValidationError
                    name='confirmPassword'
                    touched={touched}
                    error={errors}
                  />
                </div>
              </>
              }

              {
                isOtpFound && !isValidOtp && <>
                  <div className='form-outline'
                    style={{ textAlign: "center", padding: "0 3px", justifyContent: "center", display: "flex" }}>
                    <OTPInput
                      inputStyle={{
                        width: "46px",
                        height: "43px",
                        textAlign: "center",
                        borderRadius: '4px',
                        border: '1px solid #ccc',
                      }}
                      value={otp}
                      shouldAutoFocus
                      className="otp-input-div"
                      onChange={(value) => {
                        setError(false);
                        setOtp(value);
                        ga4Events({
                          action: 'otp_entered',
                          category: 'User',
                        });
                      }}
                      numInputs={6}
                      inputType='number'
                      renderSeparator={<span style={{ paddingLeft: 10 }}></span>}
                      renderInput={(props) => <input {...props}
                        type="text"
                        inputmode="numeric"
                      />}
                    />
                  </div>
                  <div className="text-danger" style={{ justifyContent: "center", display: "flex" }}>
                    {error && errorMessage && errorMessage}
                  </div>
                  {
                    isOtpFound && !isValidOtp && !isValidOtpByPhone && !isSkip && <>
                      <div class='text-right mt-1'>
                        <p style={{ fontSize: '14px', fontWeight: "bold" }}>
                          <a
                            className=''
                            href='#!'
                            onClick={(e) => {
                              e.preventDefault();
                              onResetSubmit(values);
                              ga4Events({
                                action: 'otp_resent',
                                category: 'User'
                              });
                            }}
                          >
                            RESEND OTP
                          </a>
                        </p>
                      </div>
                    </>
                  }
                </>
              }

              {
                isForgetPass && !isOtpFound && <>
                  <>
                    {isSignWithEmail ? (
                      <div className='form-outline mb-3'>
                        <TextField
                          id='standard-basic'
                          name='email'
                          type={'email'}
                          fullWidth
                          placeholder=''
                          variant='outlined'
                          hiddenLabel
                          onChange={handleChange}
                          value={values.email}
                          onBlur={handleBlur}
                          className={classes.customTextField}
                        />
                        <FormikValidationError
                          name='email'
                          touched={touched}
                          error={errors}
                        />
                      </div>
                    ) : (
                      <div className='form-outline mb-3'>
                        <PhoneInput
                          placeholder='Enter phone number'
                          name='phone'
                          country={'ae'}
                          value={remove4th0(value?.replace(/\s/g, '').trim())}
                          inputStyle={{
                            height: '3.8rem',
                            fontSize: '15px',
                          }}
                          onChange={(phoneNumber, country, e) => {
                            e.preventDefault();

                            handleInputChange(e);
                            if (selectedCountry !== country?.countryCode) {
                              ga4Events({
                                action: 'country_changed',
                                category: 'User',
                              });
                              setSelectedCountry(country?.countryCode)
                            }
                          }}
                          onBlur={(e) => {
                            e.preventDefault();
                            onBlurChnage(e);
                          }

                          }
                        />  {errorM && <div className="text-danger">{errorM}</div>}
                        {customErrorData?.phone && !value?.length && (
                          <span className='text-danger'>
                            {' '}
                            <span className='ic-error'></span>
                            Phone number is required
                            {/* {(!invalidPhoneNumber && !value?.length) ? "Phone number is required" : "Invalid phone number"} */}
                          </span>
                        )}
                      </div>
                    )}
                  </>
                </>
              }
              {
                isSignWithEmail && !isForgetPass && !isOtpFound && <>
                  <div className='form-outline mb-3'>
                    <TextField
                      id='standard-basic'
                      name='email'
                      type='text'
                      fullWidth
                      placeholder='Email ID'
                      variant='outlined'
                      hiddenLabel
                      onChange={handleChange}
                      value={values.email}
                      onBlur={handleBlur}
                      className={classes.customTextField}
                    />
                    <FormikValidationError
                      name='email'
                      touched={touched}
                      error={errors}
                    />
                  </div>
                  {
                    emailLoginPasswordShow &&
                    <div className='form-outline mb-4'>
                      <TextField
                        id='standard-basic'
                        name='password'
                        type={passwordVisibility ? 'text' : 'password'}
                        fullWidth
                        placeholder='Password'
                        variant='outlined'
                        hiddenLabel
                        InputProps={{
                          endAdornment: (
                            <InputAdornment
                              position='end'
                              style={{
                                backgroundColor: 'transparent',
                                fontSize: '14px',
                              }}
                            >
                              <IconButton
                                aria-label='toggle password'
                                edge='end'
                                onClick={() =>
                                  setPasswordVisibility(!passwordVisibility)
                                }
                                onMouseDown={handleMouseDownPassword}
                              >
                                {passwordVisibility ? (
                                  <img
                                    style={{ width: "20px" }}
                                    src="/assets/img/eye-visiable.svg"
                                    alt="Help"
                                  />
                                ) : (
                                  <img
                                    style={{ width: "20px" }}
                                    src="/assets/img/eye-visiable-off.svg"
                                    alt="Help"
                                  />
                                )}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                        onChange={handleChange}
                        value={values.password}
                        onBlur={handleBlur}
                        className={classes.customTextField}
                      />
                      <FormikValidationError
                        name='password'
                        touched={touched}
                        error={errors}
                      />
                    </div>
                  }

                </>
              }

              {isSignWithPhone && !isForgetPass && !isOtpFound &&
                <>
                  <div className='form-outline mb-3'>
                    <PhoneInput
                      placeholder='Enter phone number'
                      name='phone'
                      country={'ae'}
                      value={remove4th0(value?.replace(/\s/g, '').trim())}
                      inputStyle={{
                        height: '3.8rem',
                        fontSize: '15px',
                      }}
                      onChange={(phoneNumber, country, e) => {
                        e.preventDefault();
                        handleInputChange(e);

                        if (selectedCountry !== country?.countryCode) {
                          ga4Events({
                            action: 'country_changed',
                            category: 'User',
                          });
                          setSelectedCountry(country?.countryCode)
                        }
                      }}
                      onBlur={(e) => {
                        e.preventDefault();
                        onBlurChnage(e);
                      }
                      }
                    />  {errorM && <div className="text-danger">{errorM}</div>}
                    {customErrorData?.phone && !value?.length && (
                      <span className='text-danger'>
                        {' '}
                        <span className='ic-error'></span>
                        Phone number is required
                        {/* {(!invalidPhoneNumber && !value?.length) ? "Phone number is required" : "Invalid phone number"} */}
                      </span>
                    )}
                  </div>
                  <div className='form-outline mb-4'>
                    <TextField
                      id='standard-basic'
                      name='password'
                      type={passwordVisibility ? 'text' : 'password'}
                      fullWidth
                      placeholder='Password'
                      variant='outlined'
                      hiddenLabel
                      InputProps={{
                        endAdornment: (
                          <InputAdornment
                            position='end'
                            style={{
                              backgroundColor: 'transparent',
                              fontSize: '15px',
                            }}
                          >
                            <IconButton
                              aria-label='toggle password'
                              edge='end'
                              onClick={() =>
                                setPasswordVisibility(!passwordVisibility)
                              }
                              onMouseDown={handleMouseDownPassword}
                            >
                              {passwordVisibility ? (
                                <img
                                  style={{ width: "20px" }}
                                  src="/assets/img/eye-visiable.svg"
                                  alt="Help"
                                />
                              ) : (
                                <img
                                  style={{ width: "20px" }}
                                  src="/assets/img/eye-visiable-off.svg"
                                  alt="Help"
                                />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      onChange={handleChange}
                      value={values.password}
                      onBlur={handleBlur}
                    />
                    <FormikValidationError
                      name='password'
                      touched={touched}
                      error={errors}
                    />
                  </div>
                </>
              }

              {(isSignWithEmail || isSignWithPhone) && !isForgetPass && !isOtpFound && emailLoginPasswordShow &&
                <>
                  <div className='d-flex justify-content-between align-items-center mb-3'>
                    <div className='custom-control custom-checkbox checkbox-outline checkbox-outline-dark'>
                      <input
                        type='checkbox'
                        className='custom-control-input'
                        onChange={handleChange}
                        checked={values?.isChecked}
                        id='isChecked'
                      />
                      <label
                        style={{
                          color: 'black',
                          fontWeight: 600,
                        }}
                        className='custom-control-label'
                        htmlFor='isChecked'
                      >
                        Stay logged in
                      </label>
                    </div>
                    <a
                      href=' '
                      style={{
                        fontSize: '14px',
                        fontWeight: 550,
                      }}
                      onClick={(e) => {
                        e.preventDefault();
                        setIsForgetPass(true);
                        // resetForm();
                        setValue('');
                        setMobileValue('');
                        setIsValidOtpByPhone(false);
                        ga4Events({
                          action: 'forget_password_btn_click',
                          category: 'User'
                        });

                      }}
                    >
                      Forgot Your Password?
                    </a>
                  </div>
                </>
              }
              {
                isOtpByPhone && !isValidOtpByPhone && !isSkip && registeredUser && <>
                  <div class='text-right mt-2'>
                    <p style={{ fontSize: '14px', fontWeight: "bold" }}>
                      {formatTime(seconds)} <span style={{ paddingLeft: 5, paddingRight: 5 }}>|</span>
                      <a
                        className=''
                        href='#!'
                        onClick={(e) => {
                          e.preventDefault();
                          resendOtpForMobile(values);
                          ga4Events({
                            action: 'otp_resent',
                            category: 'User'
                          });
                        }}
                        style={{ pointerEvents: seconds > 0 && "none" }}
                      >
                        RESEND OTP
                      </a>
                    </p>
                  </div>
                </>
              }
              {
                !isSkip && (isOtpFound && !isValidOtp
                  || (isOtpByPhone && !isSignWithEmail && !isSignWithPhone && !isForgetPass)) &&
                  !registeredUser ?
                  <>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                      <button
                        type='submit'
                        className={`btn submitBtn mb-2 ${selectedUserType == 'Business' ? 'btn-user-type' : 'btn-outline-warning'}`}
                        style={{
                          whiteSpace: 'nowrap',
                          fontSize: '14px',
                        }}
                        onClick={() => setSelectedUserType('Business')}
                      >
                        Business
                      </button>
                      <h5 style={{ marginTop: '8px', fontWeight: 'bold' }}>Or</h5>
                      <button
                        type='submit'
                        className={`btn submitBtn mb-2 ${selectedUserType == 'Individual' ? 'btn-user-type' : 'btn-outline-warning'}`}
                        style={{
                          whiteSpace: 'nowrap',
                          fontSize: '14px',
                        }}
                        onClick={() => setSelectedUserType('Individual')}
                      >
                        Individual
                      </button>
                    </div>
                    <div style={{ fontSize: '9px' }}>Valid trade License required</div>
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                      <button
                        type='submit'
                        className='btn submitBtn mb-2 mt-4'
                        style={{
                          backgroundColor: '#f39200',
                          color: '#ffff',
                          whiteSpace: 'nowrap',
                          fontSize: '14px',
                        }}
                        disabled={!selectedUserType}
                        onClick={() => setRegisteredUser(true)}
                      >
                        CONTINUE
                      </button>
                    </div>
                  </>
                  :
                  !isSkip &&
                  <button
                    type='submit'
                    className='btn btn-block submitBtn mb-2'
                    style={{
                      backgroundColor: '#f39200',
                      color: '#ffff',
                      whiteSpace: 'nowrap',
                      fontSize: '14px',
                    }}
                    disabled={(!isSignWithEmail && isPopup) || ((isSignWithEmail && values.email.length == 0)
                      || errors?.email?.length ? true : false) || errorM.length ? true : false
                        || isLoading ? true : false || ((isOtpFound && !isValidOtp && !isForgetPass || (isOtpByPhone && !isSignWithEmail && !isSignWithPhone && !isForgetPass)) && mobileOtp.length < 6) ? true : false
                          || emailLoginPasswordShow && !values.password && !isForgetPass ? true : false
                    }
                  >
                    {isLoading && <i className='las la-spinner la-spin mr-2'></i>}
                    {isValidOtp
                      ? 'SAVE PASSWORD'
                      : isOtpFound
                        ? 'CONTINUE TO RESET PASSWORD'
                        : isValidOtpByPhone ? "CONTINUE"
                          : (isOtpFound && !isValidOtp || (isOtpByPhone && !isSignWithEmail && !isSignWithPhone && !isForgetPass && registeredUser)) ? "LOGIN / REGISTER"
                            : isForgetPass
                              // ? `Get OTP on ${!isSignWithPhone ? 'email' : 'mobile number'}`
                              ? `RESET NOW`
                              : isSignWithEmail && emailLoginPasswordShow ? 'LOGIN / REGISTER' : 'CONTINUE'}
                  </button>
              }

              {isValidOtpByPhone && <div className='d-flex justify-content-between align-items-center mb-3'>
                <div className='custom-control custom-checkbox checkbox-outline checkbox-outline-dark'>
                </div>
                <u>
                  <a
                    href=' '
                    style={{
                      fontSize: '16px',
                      color: 'black',
                      fontWeight: 550,
                      marginTop: "4px"
                    }}
                    onClick={(e) => {
                      e.preventDefault();
                      handleSkip()
                    }}
                  >
                    Skip
                  </a>
                </u>
              </div>}

              {!isOtpFound && !isOtpByPhone && !emailLoginPasswordShow &&
                <div className='divider d-flex align-items-center my-3'>
                  <p
                    style={{ color: 'black', fontWeight: 600 }}
                    className='text-center mx-3 mb-0'
                  >
                    OR
                  </p>
                </div>
              }
              {
                !isSignupWithOtp && !isForgetPass && !isOtpFound && !isOtpByPhone && !isSkip && !emailLoginPasswordShow &&
                <button
                  type='button'
                  className='btn btn-block btn-outline-warning align-left submitBtn mb-2'
                  onClick={() => {
                    resetForm();
                    setValue('');
                    setMobileValue('');
                    setIsSignupWithOtp(true);
                    setSignWithPhone(false);
                    setSignWithEmail(false);
                    setIsValidOtpByPhone(false);
                    ga4Events({
                      action: 'otp_btn_click',
                      category: 'User'
                    });
                  }}
                  style={{ fontSize: '13px' }}
                >
                  <span className='push-right'>
                    <WifiCalling3OutlinedIcon style={{ marginTop: '-3px' }} />
                  </span>
                  {`LOG-IN/REGISTER USING PHONE NO.`}
                </button>
              }
              {
                !isSignWithEmail && !isOtpFound && !isOtpByPhone &&
                <button
                  type='button'
                  className='btn btn-block btn-outline-warning align-left submitBtn mb-2'
                  onClick={() => {
                    resetForm();
                    setValue('');
                    setMobileValue('')
                    setIsPopup(true);
                    setSignWithEmail(true);
                    setSignWithPhone(false)
                    setIsSignupWithOtp(false);
                    setIsValidOtpByPhone(false)
                    !isForgetPass && ga4Events({
                      action: '_with_email/password_clicked',
                      category: 'User'
                    });
                  }}
                  style={{ fontSize: '13px' }}
                >
                  <span className='push-right'>
                    <EmailOutlinedIcon style={{ marginTop: '-3px' }} />
                  </span>
                  {`${isForgetPass ? " Reset using Email ID" : "LOG-IN/REGISTER USING EMAIL"}`}
                </button>
              }
              {/* {
                !isSignWithPhone && !isOtpFound && !isOtpByPhone &&
                <button
                  type='button'
                  className='btn btn-block btn-light align-left'
                  onClick={() => {
                    resetForm();
                    setValue('');
                    setMobileValue('');
                    setSignWithPhone(true);
                    setIsSignupWithOtp(false);
                    setIsValidOtpByPhone(false);
                    setSignWithEmail(false);
                    !isForgetPass && ga4Events({
                      action: '_with_mobile/password_clicked',
                      category: 'User'
                    });
                  }}
                  style={{ fontSize: '15px' }}
                >
                  <span className='push-right'>
                    {' '}
                    <img
                      src='/assets/img/mobile.png'
                      alt='phone'
                      style={{ fontSize: 37, height: 25 }}
                    />
                  </span>
                  {`${isForgetPass ? " Reset using Mobile" : " with Mobile and Password"}`}
                </button>
              } */}

              {isSkip && <button
                type='button'
                className='btn btn-block btn-light align-left'
                onClick={() => {
                  setIsValidOtpByPhone(true);
                  setIsSkip(false)
                }}
                style={{ fontSize: '15px', marginTop: "10px" }}
              >
                <span className='push-right'>
                  {' '}

                </span>
                {`Go Back`}
              </button>}

            </form>
            <div style={{ position: "relative" }}>
              <div className={`help-pop-modal ${isHelpModal ? 'showModal' : 'hideModal'}`} >
                <ul >
                  <a onClick={handleGaEventWhatsApp} href={`https://api.whatsapp.com/send?phone=${process.env.REACT_APP_WHATSAPP_NUMBER}`} target='_blank'>
                    <li className="help-list">
                      <WhatsAppIcon className="help-svg" style={{ color: '#44c052' }} />
                      WhatsApp
                    </li>
                  </a>
                  <div onClick={(e) => {
                    toggle(e);
                    setIsHelpModal(false);
                  }}>
                    <NavLink
                      to='/contact' >
                      <li className="help-list">
                        <CallRoundedIcon className="help-svg" />
                        Contact Us
                      </li>
                    </NavLink>
                  </div>
                </ul>
              </div>
            </div>
            <div
              className='help-content'
              onClick={() => setIsHelpModal(!isHelpModal)}
            >
              <img
                style={{ width: "32px" }}
                src="/assets/img/help.svg"
                alt="Help"
              />
            </div>
          </div>
        </ModalBody>
      </Modal>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    login: state.login,
  };
};
const mapDispatchToProp = (dispatch) => {
  return {
    login: (data) => dispatch(LogInAc(data)),
    visitor: (data) => dispatch(visitor1(data)),
  };
};

export default compose(
  withTranslation(),
  connect(mapStateToProps, mapDispatchToProp)
)(LoginModal);
