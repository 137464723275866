import { Range } from 'rc-slider'
import React, { useEffect, useState } from 'react'
import { numberWithCommas } from '../../../../utils'
// import MakeListData from '../../../container/MakeListData'
import { OptionSelection } from '../../../content/element/filter/selection'

const PurchaseFilter = ({ list, currency = "AED", filterData, brandList, makes, models }) => {
    const [pageModel, setPageModel] = useState(0);
    const [pageBrand, setPageBrand] = useState(0);
    const [pageMake, setPageMake] = useState(0);
    const [searchQuery, setSearchQuery] = useState({});
    const [range, setRange] = useState([0, list.maxPrice]);

    const businessModel = [
        { _id: 'Consolidation', name: 'Consolidation' },
        { _id: 'Fulfilment', name: 'Sold by BP24' },
    ]
    const unique = (arr) =>
        arr?.length
            ? arr
                ?.map((e) => e['_id'])
                .map((e, i, final) => final.indexOf(e) === i && i)
                .filter((obj) => arr[obj])
                .map((e) => arr[e])
            : [];
    const {
        maxPrice,
        minPrice,
        categoryList,
        subCategoriesList,
        makeList,
        modelList,
    } = list
    const currentUser = JSON.parse(localStorage.getItem('login'))
    let mpu_sidebar_category_banner = list.bannersList
        ?.filter(
            (banner) =>
                banner?.archived === false &&
                banner.category === 'mpu_sidebar_category_banner'
        )
        ?.sort((a, b) => a.position - b.position);

    const categoryListsOptions = categoryList?.map((item) => {
        return {
            _id: item?._id,
            name: item?.name,
        }
    })
    const partBrandListsOptions = brandList?.map((item) => {
        return {
            _id: item?.toLowerCase(),
            name: item,
        }
    });

    const subCategoryListOptions = subCategoriesList?.map((item) => {
        return {
            _id: item?._id,
            name: item?.name,
        }
    });

    const businessModelOption = businessModel.map((item) => {
        return {
            _id: item?._id,
            name: item?.name,
        }
    });
    const makeListData = makeList && makeList.length && makeList.filter(obj => makes.includes(obj._id));
    let makeListsOptions = makeListData?.map((item) => {
        return {
            _id: item?._id,
            name: item?.name,
            id_car_make: item?.id_car_make
        }
    })

    const modelListData = modelList && modelList.length && modelList.filter(obj => models.includes(obj._id));
    const modelListsOptions = modelListData?.map((item) => {
        return {
            _id: item?._id,
            name: item?.name,
        }
    })

    const filteredMake = unique(makeListsOptions)?.slice(
        pageMake * 10,
        pageMake * 10 + 10
    )

    const filteredModel = unique(modelListsOptions)?.slice(
        pageModel * 10,
        pageModel * 10 + 10
    )

    const filteredBrand = unique(partBrandListsOptions)?.slice(
        pageBrand * 10,
        pageBrand * 10 + 10
    )

    const totalpagemodel = Math.ceil(unique(modelListsOptions)?.length / 10)
    const totalpagemake = Math.ceil(unique(makeListsOptions)?.length / 10)
    const totalpagebrand = Math.ceil(unique(partBrandListsOptions)?.length / 10)

    const handleSelectionChange = (options, name) => {
        searchQuery[name] = options;
        setSearchQuery(searchQuery);
        filterData(searchQuery);
    }

    const handlePriceChange = (data) => {
        searchQuery['minPrice'] = data[0];
        searchQuery['maxPrice'] = data[1];
        setSearchQuery(searchQuery);
        filterData(searchQuery);
        setRange(data);
    }

    const handlePriceAfterChange = (data) => {
        searchQuery['minPrice'] = data[0];
        searchQuery['maxPrice'] = data[1];
        filterData(searchQuery);
    }

    const resetFilters = () => {
        setSearchQuery({});
        filterData({});
    }

    return (
        <>
            {list.page !== 'collection' &&
                mpu_sidebar_category_banner?.length > 0 && (
                    <>
                        {mpu_sidebar_category_banner?.map((banner, index) => {
                            return (
                                <div
                                    className='hide-banner'
                                    onClick={(e) => {
                                        this.handleGaEvent(banner?._id)
                                        !currentUser &&
                                            this.toggle(e, banner?.banner_link)
                                    }}
                                    style={{
                                        alignContent: 'center',
                                        width: '385px',
                                        backgroundSize: 'cover',
                                        objectPosition: 'center',
                                        objectFit: 'cover',
                                        justifyContent: 'center',
                                        marginBottom: '20px',
                                        borderRadius: '10px',
                                        zIndex: 3,
                                    }}>
                                    <a href={currentUser && banner?.banner_link}>
                                        <img
                                            className='top-M-content'
                                            style={{
                                                borderRadius: '10px',
                                                height: '175px',
                                            }}
                                            src={banner?.banner_upload}
                                            alt='banner'
                                        />
                                    </a>
                                </div>
                            )
                        })}
                    </>
                )}
            <div
                className='listings-sidebar'
                style={{ marginTop: '10px' }}>
                <div className='search-area default-ad-search'>
                    <div
                        className='search-area-header'
                        style={{ color: 'black' }}>
                        Filters
                        <p
                            style={{
                                display: 'inline-flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                alignContent: 'center',
                                gap: 2,
                                borderBottom: '1px solid black',
                                padding: 0,
                                margin: 0,
                            }}
                            onClick={(e) => resetFilters()}>
                            <span
                                style={{
                                    border: '1px solid black',
                                    borderRadius: '50%',
                                    width: '13px',
                                    height: '13px',
                                    display: 'inline-flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    alignContent: 'center',
                                    color: 'black',
                                    padding: 0,
                                    margin: 0,
                                }}>
                                <span style={{ fontSize: '9px' }}>X</span>
                            </span>
                            <span
                                style={{
                                    color: 'black',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    alignContent: 'center',
                                    padding: 0,
                                    margin: 0,
                                }}>
                                Clear Filters
                            </span>
                        </p>
                    </div>

                    <div
                        className='accordion'
                        id='filterAccordion'
                        role='tablist'
                        aria-multiselectable='true'>
                        <div className='card'>
                            <div className='card'>
                                <div
                                    className='card-header'
                                    role='tab'
                                    id='headingOne6'>
                                    <a
                                        data-toggle='collapse'
                                        href='#collapseOne6'
                                        aria-expanded='true'
                                        aria-controls='collapseOne6'>
                                        <span className='mb-0'>
                                            Business Model
                                            {`${searchQuery.businessModel?.length > 0
                                                ? ' (' +
                                                searchQuery.businessModel?.length +
                                                ')'
                                                : ''
                                                }`}{' '}
                                        </span>
                                        <i className='la la-angle-up'></i>
                                    </a>
                                </div>

                                <div
                                    id='collapseOne6'
                                    className='collapse show'
                                    role='tabpanel'
                                    aria-labelledby='headingOne6'>
                                    <OptionSelection
                                        options={businessModelOption || []}
                                        name='businessModel'
                                        activeOptions={
                                            searchQuery?.businessModel ? searchQuery.businessModel : []
                                        }
                                        onChange={handleSelectionChange}
                                    />
                                </div>
                            </div>
                            <div
                                className='card-header'
                                role='tab'
                                id='headingOne1'>
                                <a
                                    data-toggle='collapse'
                                    data-parent='#filterAccordion'
                                    href='#collapseOne1'
                                    aria-expanded='true'
                                    aria-controls='collapseOne1'>
                                    <span className='mb-0'>Price</span>
                                    <i className='la la-angle-up'></i>
                                </a>
                            </div>
                            <div
                                id='collapseOne1'
                                className='collapse show'
                                role='tabpanel'
                                aria-labelledby='headingOne1'
                                data-parent='#filterAccordion'>
                                <div className='card-body'>
                                    <div className='form-group'>
                                        <div className='price-range rs-primary'>
                                            <p className='d-flex justify-content-between'>
                                                <span className='amount'>
                                                    {`${numberWithCommas(range[0])}${currency
                                                        }  - ${numberWithCommas(range[1])}${currency} `}
                                                </span>
                                            </p>

                                            <Range
                                                min={minPrice}
                                                max={maxPrice}
                                                defaultValue={[minPrice, maxPrice]}
                                                value={
                                                    range
                                                }
                                                onChange={handlePriceChange}
                                                onAfterChange={handlePriceAfterChange}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                        {filteredMake?.length > 0 && (
                            <div className='card'>
                                <div
                                    className='card-header'
                                    role='tab'
                                    id='headingOne3'>
                                    <a
                                        data-toggle='collapse'
                                        href='#collapseOne3'
                                        aria-expanded='true'
                                        aria-controls='collapseOne3'>
                                        <span className='mb-0'>
                                            Make

                                        </span>
                                        <i className='la la-angle-up'></i>
                                    </a>
                                </div>
                                <div
                                    id='collapseOne4'
                                    className='collapse show'
                                    role='tabpanel'
                                    aria-labelledby='headingOne4'>
                                    <OptionSelection
                                        options={filteredMake}

                                        page={pageModel}
                                        setpage={(page) => setPageMake(page)}
                                        totalpage={totalpagemake}
                                        show={true}
                                        name='make'
                                        activeOptions={
                                            searchQuery?.make ? searchQuery.make : []
                                        }
                                        onChange={handleSelectionChange}
                                    />
                                </div>


                            </div>
                        )}

                        {filteredModel?.length > 0 && (
                            <div className='card'>
                                <div
                                    className='card-header'
                                    role='tab'
                                    id='headingOne4'>
                                    <a
                                        data-toggle='collapse'
                                        href='#collapseOne4'
                                        aria-expanded='true'
                                        aria-controls='collapseOne4'>
                                        <span className='mb-0'>
                                            {'Model'}

                                        </span>
                                        <i className='la la-angle-up'></i>
                                    </a>
                                </div>
                                <div
                                    id='collapseOne4'
                                    className='collapse show'
                                    role='tabpanel'
                                    aria-labelledby='headingOne4'>
                                    <OptionSelection
                                        options={filteredModel}

                                        page={pageModel}
                                        setpage={(page) => setPageModel(page)}
                                        totalpage={totalpagemodel}
                                        show={true}
                                        name='model'
                                        activeOptions={
                                            searchQuery?.model ? searchQuery.model : []
                                        }
                                        onChange={handleSelectionChange}
                                    />
                                </div>
                            </div>
                        )}

                        {filteredBrand && filteredBrand?.length > 0 && (
                            <div className='card'>
                                <div
                                    className='card-header'
                                    role='tab'
                                    id='headingOne5'>
                                    <a
                                        data-toggle='collapse'
                                        href='#collapseOne5'
                                        aria-expanded='true'
                                        aria-controls='collapseOne5'>
                                        <span className='mb-0'>
                                            Part Brands

                                        </span>
                                        <i className='la la-angle-up'></i>
                                    </a>
                                </div>

                                <div
                                    id='collapseOne5'
                                    className='collapse show'
                                    role='tabpanel'
                                    aria-labelledby='headingOne5'>
                                    <OptionSelection
                                        options={filteredBrand}
                                        page={pageBrand}
                                        setpage={(page) => setPageBrand(page)}
                                        totalpage={totalpagebrand}
                                        show={true}
                                        name='brands'
                                        activeOptions={
                                            searchQuery?.brands ? searchQuery.brands : []
                                        }
                                        onChange={handleSelectionChange}
                                    />
                                </div>
                            </div>
                        )}
                    </div>

                </div>
            </div>
        </>
    )
}

export default PurchaseFilter