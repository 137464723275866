import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import SimpleReactValidator from "simple-react-validator";
import Select from "react-select";
import { useHistory } from "react-router-dom";

import { updateUserProfile } from "../../../../../Store/action/userAction";
import { getGarageType, isValidObject, popUp, validateFile } from "../../../../../utils";
import { emiratesOptions, isVatRegisterOptions } from "../../../../../constants";
import GoogleMapLocation from "../../../../common/GoogleMapLocation";
import SelectionInput from "../../../../common/SelectionInput";
import SearchCityInput from "../../../../common/SearchCityInput";
import { uploadFileToAssets } from "../../../../../services/upload";
import UploadProfileImg from "./UploadProfileImg";
//import Select, { SelectChangeEvent } from '@mui/material/Select';

const BuyerProfile = () => {
    const dispatch = useDispatch();
    const history = useHistory();

    const uploadRef = useRef();
    const validator = useRef(new SimpleReactValidator());

    const [avatar, setAvatar] = useState("");
    const [details, setDetails] = useState({});

    const [stateData, setStateData] = useState([]);
    const [regions, setRegions] = useState([]);

    const [submitLoading, setSubmitLoading] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);

    const [, forceUpdate] = useState();


    const { login } = useSelector((state) => {
        return {
            login: state.login
        }
    })  

    useEffect(() => { 
            let { avatar, details } = login;  

            let getCarType = [];
            
            details?.car_type?.map((oo, ii) => {  
                    let carValue = oo?.value || oo;
                    getCarType.push({value: carValue, label: carValue}); 
            });            
       
            let getCategoryType = [];
            
            details?.category_type?.map((oo, ii) => {   
                let carValue = oo?.value || oo;
                getCategoryType.push({value: carValue, label: carValue}); 
            }); 

            let getJobWork = [];
           
            details?.job_work_type?.map((oo, ii) => {  
                let carValue = oo?.value || oo;
                if(oo.value && oo.value !== undefined){  
                    getJobWork.push({value: oo.value, label: oo.label}); 
                }else{
                    getJobWork.push({value: carValue, label: carValue}); 
                }
            })
           
            let noServiced = '';
             
            if(typeof details?.car_service_type === 'number'){
                noServiced = details?.car_service_type+"+";
            }else{
                noServiced = details?.car_service_type?.split("-");
                if(noServiced){
                    if(noServiced.length > 1 && (noServiced[1] == 0 || noServiced[1] == null)){
                        noServiced = noServiced[0]+"+";
                    }else{
                        if(noServiced.length == 0){
                            noServiced = details?.car_service_type+"+";
                        }else{
                            noServiced = details?.car_service_type;
                        }
                    }
                }
            } 
          
            if (details && details.garage_address) {
               
                const truecheck = details?.garage_address;
                const arr = details?.garage_address?.split(",");
    
                const [area, ...companyAddress] = arr || [];
                const newaddres = companyAddress?.join();
                
                details = {
                    ...details,
                    garage_address: details?.garage_address || "",
                    garage_area:  details?.garage_area || null,
                    garage_type: details?.garage_type,
                    car_type: getCarType,
                    category_type: getCategoryType,
                    car_service_type: noServiced,
                    job_work_type: getJobWork,
                }
                
            } else { 
                
                details = {
                    ...details,
                    garage_address: "",
                    garage_type: details?.garage_type,
                    car_type: getCarType,
                    garage_category: details?.garage_category,
                    category_type: getCategoryType,
                    car_service_type: noServiced,
                    job_work_type: getJobWork,
                    garage_area: null
                }
            } 
             
            setAvatar(avatar);
            setDetails(details);
        
    }, [login]);

    //GETTING LIST OF REGIONS
    useEffect( () => { 
        const fetchRegions = async () => {
           await axios
            .get("/api/products/get-regions")
            .then((res) => {
                 
                 let newRegions = res?.data?.regions?.filter(region => login?.details?.emirate === null ? region?.state?.name : (region?.state?.name?.toLowerCase() === login?.details?.emirate?.toLowerCase()));
                 setRegions(res.data.regions);
                 setStateData(newRegions)
                 return () =>{
                 setRegions(res.data.regions);
                 setStateData(newRegions)
                }
            })
            .catch((e) =>
                popUp(e?.response?.data?.message || "Error while fetching regions")
            );
        } 
        fetchRegions();
    }, [login]);

   
    //UPDATING THE LOCATION AND LAT LNG
    const updateLocation = (address, cordinates) => {
        const data = {
            ...details,
            buyer_location: address,
            latLong: cordinates
        }
        setDetails(data)
    }

    //TOGGLE FUNCTION TO OPEN LOCATION MODAL
    const toggle = (e) => {
        e.preventDefault();
        setIsModalOpen(prev => !prev)
    };

    //SETTING THE STATE ON SELECT INPUT
    const handleSelect = (selectedOption, fieldAttr) => {
        if (fieldAttr.name === "emirate") {
            let newRegions = regions?.filter(region => region?.state?.name?.toLowerCase() === selectedOption.value?.toLowerCase());

            setStateData(newRegions);
            setDetails({
                ...details,
                garage_area: ""
            });
        }
        setDetails({
            ...details,
            [fieldAttr.name]: selectedOption.value
        })
    }

    // SETTING THE STATE ON INPUT CHANGE
    const handleChange = (e) => {
        e.preventDefault();
        if (e.target.name === 'break_open') {
            let garage_break_time = details?.garage_break_time?.length ? [...details?.garage_break_time] : []
            garage_break_time[0] = e.target.value

            setDetails({
                ...details,
                garage_break_time
            })
            return
        }

        if (e.target.name === 'break_close') {
            let garage_break_time = details?.garage_break_time?.length ? [...details?.garage_break_time] : []
            garage_break_time[1] = e.target.value;

            setDetails({
                ...details,
                garage_break_time
            })
            return;
        }

        setDetails({
            ...details,
            [e.target.name]: e.target.value
        });
    }

    //SETTING THE STATE ON ADDRESS FIELD CHANGE
    const setAddress = (address) => {
        setDetails({
            ...details,
            garage_address: address
        });
    }

    //SETTING THE STATE ON FILE INPUT CHANGE
    const setSelectedFile = (file, name) => {
        setDetails({
            ...details,
            [name]: file
        });
    }

    //FUNCTION ON UPDATE SUCCESS
    const onSuccess = () => {
        popUp("Profile Updated Successfully !");
        setSubmitLoading(false);
        history.push('/my-dashboard');
    }

    //FUNCTION ON UPDATE FAIL
    const onFail = () => {
        setSubmitLoading(false);
    } 

    //FUNCTION CALLED AFTER CLICKING SUBMIT BUTTON
    const handleSubmit = async (e) => {
       
        try {
            e.preventDefault();
            console.log("validator.currentvalidator.currentvalidator.currentvalidator.currentvalidator.current",validator.current);
            if (validator.current.allValid()) {
                setSubmitLoading(true);

                let formData = {};
                const { garage_area, garage_address, ...rest } = details;
                const fulladdress = `${garage_area},${garage_address}`;

                let detailData = {
                    ...rest,
                    garage_address: garage_address
                };

                const fileArray = [];
                if (isValidObject(avatar))
                    fileArray.push(uploadFileToAssets({ file: avatar, type: 'profile' }));
                else
                    fileArray.push(Promise.resolve({
                        _id: avatar || "",
                    }))

                if (details?.trade_license_upload && isValidObject(details?.trade_license_upload))
                    fileArray.push(uploadFileToAssets({ file: details?.trade_license_upload, type: 'user' }));
                else
                    fileArray.push(Promise.resolve({
                        _id: details?.trade_license_upload || "",
                    }))

                if (details?.vat_registration_upload && isValidObject(details?.vat_registration_upload))
                    fileArray.push(uploadFileToAssets({ file: details?.vat_registration_upload, type: 'user' }));
                else
                    fileArray.push(Promise.resolve({
                        _id: details?.vat_registration_upload || "",
                    }))

                if (details?.emirates_id && isValidObject(details?.emirates_id))
                    fileArray.push(uploadFileToAssets({ file: details?.emirates_id, type: 'user' }));
                else
                    fileArray.push(Promise.resolve({
                        _id: details?.emirates_id || "",
                    }))

                const [avatarImg, trade_license_upload, vat_registration_upload, emirates_id] = await Promise.all(fileArray);

                detailData = {
                    ...detailData,
                    trade_license_upload: trade_license_upload?._id,
                    vat_registration_upload: vat_registration_upload?._id,
                    emirates_id: emirates_id?._id
                }

                formData = {
                    avatar: avatarImg?._id,
                    details: detailData
                }

                dispatch(updateUserProfile({
                    formData,
                    userId: login?._id,
                    onSuccess,
                    onFail
                }));
            } else {
                validator.current.showMessages();
                forceUpdate(1);
            }
        } catch (error) {
            console.log("errrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrr",error);
            popUp(error?.message);
            setSubmitLoading(false);
        }
    }

    return (
        <div className="row mt-2">
            <UploadProfileImg
                avatar={avatar}
                setAvatar={setAvatar}
            />

            <div className="col-lg-9 col-md-8">
                <div className="atbd_author_module">
                    <div className="atbd_content_module">
                        <div className="atbd_content_module__tittle_area">
                            <div className="atbd_area_title">
                                <h4>
                                    <span className="la la-user"></span>
                                    My Profile
                                </h4>
                            </div>
                        </div>
                        <div className="atbdb_content_module_contents">
                            <div className="user_info_wrap">
                                <div className="form-group">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <label htmlFor="emirate" className="form-label">
                                                Emirate
                                            </label>  
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    id="emirate"  
                                                    value={details?.emirate || ""}
                                                    name="emirate"
                                                    isDisabled={true}
                                                />  
                                        </div>
                                    </div>
                                </div>

                                <div className="form-group">
                                    <label htmlFor="garage-type" className="form-label">
                                        Company Type 
                                    </label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="garage-type" 
                                        placeholder={details?.garage_type !== 'B2C' ? "Enter Garage Name" : 'Name'}
                                        value={details?.garage_type || ""}
                                        name="garage_type"
                                        isDisabled={true}
                                    />  
 
                                </div>
                            { details?.garage_type !== 'B2C' && 
                                <>
                                <div className="form-group no-dropdown">
                                    <label htmlFor="car-type" className="form-label">
                                        Car Type
                                    </label> 

                                    <Select
                                      className={`react-select`}
                                      classNamePrefix="react-select"
                                        name="car_type"
                                        id="car-type"
                                        isMulti 
                                        isDisabled={true}
                                        value={details?.car_type}
                                    />
                                </div> 
                                
                                <div className="form-group no-dropdown">
                                    <label htmlFor="category-type" className="form-label">
                                        Category Type
                                    </label>
                                    <Select
                                      className={`react-select`}
                                      classNamePrefix="react-select"
                                        name="category_type"
                                        id="category-type"
                                        isMulti 
                                        isDisabled={true}
                                        value={details?.category_type}
                                    /> 
                                </div>
                                <div className="form-group no-dropdown">
                                    <label htmlFor="car-serviced" className="form-label">
                                        No. of Cars Serviced
                                    </label>
                                    <Select
                                      className={`react-select`}
                                      classNamePrefix="react-select"
                                        name="car_service_type"
                                        id="car-serviced" 
                                        isDisabled={true} 
                                        value={{
                                            label: details?.car_service_type || "",
                                            value: details?.car_service_type || "",
                                        }}
                                    />
                                </div>
                                <div className="form-group no-dropdown">
                                    <label htmlFor="job-work" className="form-label">
                                    Job Work/Service Offering
                                    </label>
                                  
                                     <Select
                                      className={`react-select`}
                                      classNamePrefix="react-select"
                                        name="job_work_type"
                                        id="job-work"
                                        isMulti
                                        isDisabled={true} 
                                        value={ details?.job_work_type }
                                    />
                                </div>
                                </>
                            }          
                                <div className="form-group">
                                    <label htmlFor="garage-address" className="form-label">
                                      { details?.garage_type !== 'B2C' ? 'Garage Address' : 'Address'}   <span style={{ color: 'red' }}>*</span>
                                    </label>
                                    <div className="row">
                                       
                                        <div className="col-md-4">
                                            <Select
                                                className={`react-select`}
                                                classNamePrefix="react-select"
                                                name="garage_area"
                                                id="garage_area"
                                                placeholder="Choose Garage Area"
                                                options={stateData?.map((type) => {
                                                    return {
                                                        label: type.name,
                                                        value: type.name,
                                                    };
                                                })}
                                                onChange={handleSelect}
                                                value={{
                                                    label: details?.garage_area || "",
                                                    value: details?.garage_area || "",
                                                }}
                                            />
                                            <div className="text-danger">
                                                {validator.current.message(
                                                    "garage_area",
                                                    details?.garage_area,
                                                    `required`
                                                )}
                                            </div>
                                        </div>
                                        <div className="col-md-8">
                                            <SearchCityInput
                                                placeholder="Enter a city"
                                                address={details?.garage_address}
                                                setAddress={setAddress}
                                            />
                                            <div className="text-danger">
                                                {validator.current.message(
                                                    "garage_address",
                                                    details?.garage_address,
                                                    `required`
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="form-group">
                                    <label htmlFor="garage-name" className="form-label">
                                     { details?.garage_type !== 'B2C' ? 'Garage Name' : 'Name' }    <span style={{ color: 'red' }}>*</span>
                                    </label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="garage-name"
                                        placeholder="Enter Garage Name"
                                        value={details?.garage_name || ""}
                                        name="garage_name"
                                        onChange={handleChange}
                                        required
                                    />
                                    <div className="text-danger">
                                        {validator.current.message(
                                            "garage_name",
                                            details?.garage_name,
                                            `required`
                                        )}
                                    </div>
                                </div>

                                <div className="form-group">
                                    <SelectionInput
                                        input={details?.buyer_location}
                                        setInput={(value) => {
                                            setDetails({
                                                ...details,
                                                buyer_location: value
                                            })
                                        }}
                                        label={details?.garage_type !== 'B2C' ? "Buyer Location" : "Location"}
                                        name="buyerLocation"
                                        btnLabel="Change"
                                        eventFn={toggle}
                                        required={true}
                                    />
                                    <div className="text-danger">
                                        {validator.current.message(
                                            "buyerLocation",
                                            details?.buyer_location,
                                            `required`
                                        )}
                                    </div>
                                </div>

                                <GoogleMapLocation
                                    selectedAddress={details?.buyer_location}
                                    selectedLatitude={details?.latLong?.lat}
                                    selectedLongitude={details?.latLong?.lng}
                                    isModalOpen={isModalOpen}
                                    toggle={toggle}
                                    updateLocation={updateLocation}
                                    label="Buyer Location"
                                />
                        { details?.garage_type !== 'B2C' && 
                            <>
                                <div className="form-group">
                                    <label htmlFor="opening-hours" className="form-label">
                                        Opening Hours
                                    </label>
                                    <input
                                        type="time"
                                        id="opening-hours"
                                        className="form-control directory_field"
                                        value={details?.opening_hours || ""}
                                        name="opening_hours"
                                        onChange={handleChange}
                                    />
                                </div>

                                <div className="form-group">
                                    <label htmlFor="closing-hours" className="form-label">
                                        Closing Hours
                                    </label>
                                    <input
                                        type="time"
                                        id="closing-hours"
                                        className="form-control directory_field"
                                        value={details?.closing_hours || ""}
                                        name="closing_hours"
                                        onChange={handleChange}
                                    />
                                </div>

                                <div className="form-group">
                                    <label htmlFor="break_open" className="form-label">
                                        Garage Break Opening Time
                                    </label>
                                    <input
                                        type="time"
                                        id="break_open"
                                        className="form-control directory_field"
                                        value={details?.garage_break_time?.length ? details?.garage_break_time[0] : ""}
                                        name="break_open"
                                        onChange={handleChange}
                                    />
                                </div>

                                <div className="form-group">
                                    <label htmlFor="break_close" className="form-label">
                                        Garage Break Closing Time
                                    </label>
                                    <input
                                        type="time"
                                        id="break_close"
                                        className="form-control directory_field"
                                        value={details?.garage_break_time?.length ? details?.garage_break_time[1] : ""}
                                        name="break_close"
                                        onChange={handleChange}
                                    />
                                </div>

                                <div className="form-group">
                                    <label htmlFor="trade-license-no" className="form-label">
                                        Trade License No  <span style={{ color: 'red' }}>*</span>
                                    </label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="trade-license-no"
                                        placeholder="Enter Trade License No"
                                        value={details?.trade_license_no || ""}
                                        name="trade_license_no"
                                        onChange={handleChange}
                                         required
                                    />
                                   
                                    {/* <div className="text-danger">
                                        {validator.current.message(
                                            "trade_license_no",
                                            details?.trade_license_no,
                                            'required'
                                        )}
                                    </div> */}
                                    
                                </div>

                                <div className="form-group">
                                    <label
                                        htmlFor="trade-license-upload"
                                        className="form-label"
                                    >
                                        Trade License Upload  <span style={{ color: 'red' }}>*</span>
                                    </label>
                                    {details?.trade_license_upload &&
                                        typeof details?.trade_license_upload !== "object" && (
                                            <p className="text-ellipse">
                                                <a
                                                    href={details?.trade_license_upload}
                                                    _target="blank"
                                                >
                                                    {details?.trade_license_upload}
                                                </a>
                                            </p>
                                        )}
                                    {login?.status === "Approved" &&
                                        details?.trade_license_upload ? (
                                        <input
                                            type="file"
                                            className="form-control fix-padding"
                                            disabled={true}
                                        />
                                    ) : (
                                        <input
                                            type="file"
                                            className="form-control fix-padding"
                                            id="trade-license-upload"
                                            onChange={(e) => {
                                                let isLt5M = validateFile(e.target.files[0])
                                                if (!isLt5M) {
                                                    e.target.value = ''
                                                    return
                                                }
                                                setSelectedFile(
                                                    e.target.files[0],
                                                    "trade_license_upload"
                                                )
                                            }
                                            }
                                        />
                                    )
                                    }
                                    {/* <div className="text-danger">
                                        {validator.current.message(
                                            "trade_license_upload",
                                            details?.trade_license_upload,
                                            `required`
                                        )}
                                    </div> */}
                                </div >

                                <div className="form-group">
                                    <label
                                        htmlFor="owner-manager-name"
                                        className="form-label"
                                    >
                                        Owner/Manager Name
                                    </label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="owner-manager-name"
                                        placeholder="Enter Name"
                                        value={details?.owner_manager_name || ""}
                                        name="owner_manager_name"
                                        onChange={handleChange}
                                    />
                                </div>

                                <div className="form-group">
                                    <label
                                        htmlFor="goods-receiver-name"
                                        className="form-label"
                                    >
                                        Name of Goods Receiver
                                    </label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="goods-receiver-name"
                                        placeholder="Enter Name"
                                        value={details?.goods_receiver_name || ""}
                                        name="goods_receiver_name"
                                        onChange={handleChange}
                                    />
                                </div>

                                <div className="form-group">
                                    <label htmlFor="isVatRegistered" className="form-label">
                                        Are you VAT Registered?  <span style={{ color: 'red' }}>*</span>
                                    </label>
                                    <Select
                                        className={`react-select`}
                                        classNamePrefix="react-select"
                                        name="isVatRegistered"
                                        id="isVatRegistered"
                                        placeholder="Choose is VAT..."
                                        options={isVatRegisterOptions?.map((data) => {
                                            return {
                                                label: data,
                                                value: data,
                                            };
                                        })}
                                        onChange={handleSelect}
                                        value={{
                                            label: details?.isVatRegistered || "",
                                            value: details?.isVatRegistered || "",
                                        }}
                                    />

                                    {/* <div className="text-danger">
                                        {validator.current.message(
                                            "isVatRegistered",
                                            details?.isVatRegistered,
                                            `required`
                                        )}
                                    </div> */}
                                </div>

                                {details?.isVatRegistered === 'Yes' &&
                                    <div className="form-group">
                                        <label htmlFor="vat-reg-no" className="form-label">
                                            VAT Registration No <span style={{ color: 'red' }}>*</span>
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="vat-reg-no"
                                            placeholder="Enter Registration No"
                                            value={details?.vat_registration_no || ""}
                                            name="vat_registration_no"
                                            onChange={handleChange}
                                        />
                                        <div className="text-danger">
                                            {validator.current.message(
                                                "vat_registration_no",
                                                details?.vat_registration_no,
                                                `required`
                                            )}
                                        </div>
                                    </div>
                                }

                                <div className="form-group">
                                    <label
                                        htmlFor="vat-cretificate-upload"
                                        className="form-label"
                                    >
                                        VAT Certificate Upload
                                    </label>
                                    {details?.vat_registration_upload &&
                                        typeof details?.vat_registration_upload !==
                                        "object" && (
                                            <p className="text-ellipse">
                                                <a
                                                    href={details?.vat_registration_upload}
                                                    _target="blank"
                                                >
                                                    {details?.vat_registration_upload}
                                                </a>
                                            </p>
                                        )}
                                    {login?.status === "Approved" &&
                                        details?.vat_certification_upload ? (
                                        <input
                                            type="file"
                                            className="form-control fix-padding"
                                            disabled={true}
                                        />
                                    ) : (
                                        <input
                                            type="file"
                                            className="form-control fix-padding"
                                            id="vat-cretificate-upload"
                                            onChange={(e) => {
                                                let isLt5M = validateFile(e.target.files[0])
                                                if (!isLt5M) {
                                                    e.target.value = ''
                                                    return
                                                }
                                                setSelectedFile(
                                                    e.target.files[0],
                                                    "vat_registration_upload"
                                                )
                                            }
                                            }
                                        />
                                    )
                                    }
                                </div >

                                <div className="form-group">
                                    <label
                                        htmlFor="owner_manager_emirates_id"
                                        className="form-label"
                                    >
                                        Owner/Manager Emirates ID / Passport No
                                    </label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="owner_manager_emirates_id"
                                        placeholder="Enter Owner/Manager Emirates ID / Passport No"
                                        value={details?.owner_manager_emirates_id || ""}
                                        name="owner_manager_emirates_id"
                                        onChange={handleChange}
                                    />
                                </div>

                                <div className="form-group">
                                    <label
                                        htmlFor="emirates-id-upload"
                                        className="form-label"
                                    >
                                        Emirates ID Upload
                                    </label>
                                    {details?.emirates_id &&
                                        typeof details?.emirates_id !== "object" && (
                                            <p className="text-ellipse">
                                                <a href={details?.emirates_id} _target="blank">
                                                    {details?.emirates_id}
                                                </a>
                                            </p>
                                        )}
                                    {login?.status === "Approved" &&
                                        details?.emirates_id ? (
                                        <input
                                            type="file"
                                            className="form-control fix-padding"
                                            disabled={true}
                                        />
                                    ) : (
                                        <input
                                            type="file"
                                            className="form-control fix-padding"
                                            id="emirates-id-upload"
                                            onChange={(e) => {
                                                let isLt5M = validateFile(e.target.files[0])
                                                if (!isLt5M) {
                                                    e.target.value = ''
                                                    return
                                                }
                                                setSelectedFile(
                                                    e.target.files[0],
                                                    "emirates_id"
                                                )
                                            }
                                            }
                                        />
                                    )}
                                </div>
                            </>
                        }

                                <div className="form-group">
                                    <button
                                        type="submit"
                                        disabled={
                                            submitLoading === false ? false : true
                                        }
                                        className="btn btn-primary btn-sm listing_submit_btn"
                                        onClick={handleSubmit}
                                    >
                                        {submitLoading && (
                                            <i className="las la-spinner la-spin mr-2"></i>
                                        )}
                                        Submit
                                    </button>
                                </div>
                            </div >
                        </div >
                    </div >
                </div >
            </div >
        </div >
    )
}

export default BuyerProfile