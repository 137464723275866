import { Pagination } from '@material-ui/lab';
import axios from 'axios';
import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { popUp } from '../../../../utils';
import PurchaseFilter from './PurchaseFilter';
import { PurchaseListingCard } from './PurchaseListingCard';


const PurchaseListing = () => {
  const [purchaseData, setPurchaseData] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [dataPerPage, setDataPerPage] = useState(20);
  const [page, setPage] = useState(1);
  const [list, setList] = useState({});
  const [brands, setBrands] = useState([]);
  const [models, setModels] = useState([]);
  const [makes, setMakes] = useState([]);
  const [searchBrandArr, setSearchBrandArr] = useState([]);
  const [searchMakeArr, setSearchMakeArr] = useState([]);
  const [searchModelArr, setSearchModelArr] = useState([]);
  const [searchBusinessModelArr, setSearchBusinessModelArr] = useState([]);
  const [maxPrice, setMaxPrice] = useState(0);
  const [minPrice, setMinPrice] = useState(0);
  const [processing, setProcessing] = useState(false);

  const currentUser = JSON.parse(localStorage.getItem("login"));

  const getPurchase = async (page) => {
    let response = await axios.post(
      `api/order/buy-again?buyer=${currentUser?._id}&status=delivered&page=${page}&size=${dataPerPage}`,
      {
        partBrand: searchBrandArr,
        makes: searchMakeArr,
        models: searchModelArr,
        business_model: searchBusinessModelArr,
        minPrice: minPrice,
        maxPrice: maxPrice
      }
    );
    setPurchaseData(response?.data[0]?.data);
    setProcessing(true);
    response?.data && response?.data?.length && setTotalCount(response?.data[0]?.paginationData?.total)
  }

  const getFilterListData = async () => {
    let emirate =
      currentUser?.role === 'BUYER' ? currentUser.details?.emirate : ''

    let URL = `/api/listings?sellerAccType=${emirate}&visibility=unhidden`
    //FOR FILTER LISTING
    await axios
      .post(`${URL}&listingFilter=true`)
      .then((res) => {
        setList(res.data);
      })
      .catch((err) => {
        popUp(err?.response?.data?.message)
      })
  }

  useEffect(() => {
    if (currentUser) {
      getPurchase(page);
      getFilterListData();
    }
  }, [searchBrandArr, searchMakeArr, searchModelArr, searchBusinessModelArr, minPrice, maxPrice]);

  const handlePagination = (e, page) => {
    setPage(page);
    getPurchase(page);
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  }

  const filterPurchaseData = useCallback(async (searchedData) => {
    if (searchedData && Object.keys(searchedData).length) {
      const searchBrandArr = searchedData.brands && searchedData.brands.map((search) => (search.name)?.toLowerCase());
      const searchMakeArr = searchedData.make && searchedData.make.map((search) => (search._id)?.toLowerCase());
      const searchModelArr = searchedData.model && searchedData.model.map((search) => (search._id)?.toLowerCase());
      const searchBusinessModelArr = searchedData.businessModel && searchedData.businessModel.map((search) => (search._id)?.toLowerCase());
      setSearchBrandArr(searchBrandArr);
      setSearchMakeArr(searchMakeArr);
      setSearchModelArr(searchModelArr);
      setSearchBusinessModelArr(searchBusinessModelArr);
      setMaxPrice(searchedData?.maxPrice);
      setMinPrice(searchedData?.minPrice);
    } else {
      setSearchBrandArr([]);
      setSearchMakeArr([]);
      setSearchModelArr([]);
      setSearchBusinessModelArr([]);
      setMaxPrice('');
      setMinPrice('');
    }

  }, [])

  useEffect(() => {
    if (purchaseData && purchaseData.length) {
      setBrands(purchaseData.map(brand => brand.listing.partBrand));
      const makeList = purchaseData.filter(makeData => makeData.listing.makes && (makeData.listing.makes).length)
        .map(make => make.listing.makes && (make.listing.makes).length && make.listing.makes[0]);
      const modelList = purchaseData.filter(modelData => modelData.listing.models && (modelData.listing.models).length)
        .map(model => model.listing.models && (model.listing.models).length && model.listing.models[0]);
      setModels(modelList);
      setMakes(makeList);
    }
  }, [processing])

  return (
    <section className='all-listing-wrapper'>
      <div className='container-fluid'>
        <div className='row'>
          <div className='col-lg-2 order-lg-0 order-0'>
            {list && Object.keys(list).length ?
              <PurchaseFilter list={list} filterData={(data) => filterPurchaseData(data)}
                brandList={brands} makes={makes} models={models} />
              : <></>}
          </div>
          <div className={'col-lg-10 order-lg-1 order-1'} style={{ paddingLeft: '2rem', paddingRight: '2rem' }}>
            <div
              className='row listing-5cards-wrapper'
              style={{ display: 'block' }}>
              <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                {
                  purchaseData && purchaseData.length ?
                    purchaseData.map((purchase, index) => {
                      return (
                        <div
                          className={'listing-card-grid'}
                          key={index}
                          style={{ minWidth: 160 }}>
                          <PurchaseListingCard
                            purchaseValue={purchase}
                          />
                        </div>
                      )
                    }) : <></>
                }
                {
                  purchaseData && purchaseData.length ?
                    <div className='container'>
                      <div className='row justify-content-center'>
                        <div className='mt-2'>
                          <Pagination
                            count={Math.ceil(totalCount / dataPerPage)}
                            variant='outlined'
                            shape='rounded'
                            page={page}
                            onChange={handlePagination}
                            style={{ marginTop: '10px' }}
                          />
                        </div>
                      </div>
                    </div>
                    : <></>
                }
                {
                  (!purchaseData || !purchaseData.length) && processing ?
                    <>
                      <div className='col-lg-12 text-center mt-5'>
                        <div
                          className='d-flex'
                          style={{ width: '57%', margin: '0 auto' }}>
                          <p
                            className='display-4'
                            style={{ margin: 'auto', marginRight: '2rem' }}>
                            <span
                              className=' display-3 font-weight-bolder'
                              style={{ color: '#1a0451', fontWeight: '800' }}>
                              Sorry
                            </span>
                            <br />{' '}
                            <span style={{ fontSize: '27px' }}>
                              We can't find any spare parts for your search
                            </span>
                          </p>
                          <img
                            src='/assets/img/no_results.png'
                            className='mt-4'
                            alt='empty'></img>
                        </div>
                      </div>
                    </>
                    : <></>
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default PurchaseListing;